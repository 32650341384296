import { Button } from '@/components/common/Button';
import Divider from '@/components/common/Divider';
import Drawer from '@/components/common/Drawer';
import Entry from '@/components/common/Entry';
import {
    DepositRequestFormInput,
    DepositRequestFormValues,
    depositRequestSchema
} from '@/components/form/schema/depositRequestSchema';
import ErrorMessage from '@/components/inputs/ErrorMessage';
import FilterSelect from '@/components/inputs/FilterSelect';
import Input from '@/components/inputs/Input';
import InputController from '@/components/inputs/InputController';
import NumberInput from '@/components/inputs/NumberInput';
import { RequestFileProofUpload } from '@/components/inputs/RequestFileProofUpload';
import Select from '@/components/inputs/Select';
import TextArea from '@/components/inputs/TextArea';
import { portalAPI } from '@/helpers/environmentHelper';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import { FileWithPath } from '@/types/common';
import { addSpacingToCharacter } from '@/utils/format';
import useToast from '@/utils/hooks/useToast';
import useXplorPortal from '@/utils/hooks/useXplorPortal';
import { useSso } from '@/utils/providers/SSOProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from '../Modal';

type Option = {
    label: string;
    value: string;
};

export enum DepositType {
    FIAT = 'FIAT',
    CRYPTO = 'CRYPTO'
}

export const depositTypeOptions: Option[] = [
    {
        label: DepositType.FIAT,
        value: DepositType.FIAT
    },
    {
        label: DepositType.CRYPTO,
        value: DepositType.CRYPTO
    }
];

const supportingDetailsTypes: Option[] = [
    {
        label: 'File',
        value: 'file'
    },
    {
        label: 'Hash',
        value: 'hash'
    }
];

const defaultBankInfoId = { label: 'Please select a bank account', value: undefined };
const defaultWalletId = { label: 'Please select a wallet', value: undefined };
const defaultCurrencyId = { label: 'Please select a currency', value: undefined };
const defaultAssetId = { label: 'Please select a asset', value: undefined };
const defaultDepositType = depositTypeOptions[0];

const defaultValues: Partial<DepositRequestFormInput> = {
    amount: '',
    currencyId: defaultCurrencyId,
    bankInfoId: defaultBankInfoId,
    walletId: defaultWalletId,
    requestDescription: '',
    depositType: defaultDepositType,
    supportingDetailsType: supportingDetailsTypes[0],
    supportingDetails: null,
    transactionHash: ''
};

interface DepositRequestModalProps extends ModalProps {}

const DepositRequestModal = (props: DepositRequestModalProps) => {
    const { opened, handlers } = props;

    const { jwt, foundEntity, bankInfoApi, currenciesApi, walletInfoApi } = useSso();

    const [error, setError] = useState({ message: '' });
    const [files, setFiles] = useState<FileWithPath[]>([]);

    const [toastError, toastSuccess] = useToast();

    const formInstance = useForm<DepositRequestFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(depositRequestSchema())
    });

    const {
        reset,
        watch,
        trigger,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = formInstance;

    const [selectedBankInfo, selectedDepositType, selectedCurrency, selectedWalletId, selectedSupportingDetailsType] =
        watch(['bankInfoId', 'depositType', 'currencyId', 'walletId', 'supportingDetailsType']);

    const setOfErrorMessages: {
        [key in 'FIAT' | 'CRYPTO']: JSX.Element;
    } = {
        FIAT: (
            <div className="flex flex-col gap-3">
                <span>
                    Please submit a Whitelist request for your bank account in {selectedCurrency?.label} at AuricPortal.
                </span>
                <RedirectToPortal />
            </div>
        ),
        CRYPTO: (
            <div className="flex flex-col gap-3">
                <span>
                    Please submit a Whitelist request for your wallet address for {selectedCurrency?.label} at
                    AuricPortal
                </span>
                <RedirectToPortal />
            </div>
        )
    };

    const { data: currencies, isLoading: isCurrenciesLoading } = currenciesApi;
    const { data: bankInfo, isLoading: isBankInfoLoading } = bankInfoApi;
    const { data: wallets, isLoading: isWalletLoading } = walletInfoApi;

    const currencyOptions = useMemo(() => {
        return currencies
            ? currencies.data
                  ?.filter((currency) => currency.enabledForWithdrawal && currency.type === selectedDepositType?.value)
                  ?.map((currency) => {
                      return {
                          label: currency.digitalAssetName || currency.name,
                          value: currency.id
                      };
                  })
            : [];
    }, [currencies, selectedDepositType]);

    const bankInfoOptions = useMemo(() => {
        return bankInfo
            ? bankInfo.data
                  ?.filter((bankInfo) => bankInfo.active)
                  ?.map((bankInfo) => ({
                      label: bankInfo.friendlyName,
                      value: bankInfo.bankInfoId
                  })) || []
            : [];
    }, [bankInfo, selectedCurrency]);

    const accountDetails = useMemo(() => {
        const selectedBankInfoDetails = bankInfo?.data?.find((info) => info.bankInfoId === selectedBankInfo?.value);
        return {
            bankName: selectedBankInfoDetails?.bankName,
            accountNumber: selectedBankInfoDetails?.accountNumber,
            ibanCode: selectedBankInfoDetails?.ibanCode,
            baseCurrency: {
                label: selectedBankInfoDetails?.baseCurrencyName,
                value: selectedBankInfoDetails?.baseCurrencyId
            }
        };
    }, [bankInfo, selectedBankInfo]);

    const walletDetails = useMemo(() => {
        const selectedWalletDetails = wallets?.data?.find((wallet) => wallet.walletId === selectedWalletId?.value);
        return {
            address: selectedWalletDetails?.walletAddress,
            network: {
                label: selectedWalletDetails?.networkName,
                value: selectedWalletDetails?.networkId
            },
            assets: selectedWalletDetails?.supportedAssets
                .map((asset) => asset.digitalAssetName || asset.currencyName)
                .join(', ')
        };
    }, [wallets, selectedWalletId]);

    const walletOptions = useMemo(() => {
        return wallets
            ? wallets.data
                  ?.filter((wallet) =>
                      wallet.supportedAssets.find((asset) => asset.currencyId === selectedCurrency?.value)
                  )
                  ?.map((wallet) => ({
                      label: wallet.friendlyName,
                      value: wallet.walletId
                  }))
            : [];
    }, [wallets, selectedCurrency]);

    const hasAccount = useMemo(() => {
        return selectedCurrency?.value && (bankInfoOptions?.length || walletOptions?.length);
    }, [selectedCurrency, bankInfoOptions, walletOptions]);

    const uploadFile = useCallback(
        async (requestId: number, entityId: number) => {
            if (files.length === 0) return false;

            const formData = new FormData();
            formData.append('file', files[0]);
            formData.append('entityId', entityId.toString());

            return await axios.postForm(portalAPI(`/api/requests/${requestId}/file-proof`), formData, {
                headers: { Authorization: `Bearer ${jwt}` }
            });
        },
        [files]
    );

    const uploadTransactionHash = useCallback(
        async (requestId: number, entityId: number, transactionHash: string) => {
            const payload = {
                id: requestId,
                entityId,
                transactionHash
            };
            return await axios.put(portalAPI('/api/requests/upload-transaction-hash'), payload, {
                headers: { Authorization: `Bearer ${jwt}` }
            });
        },
        [files]
    );

    const onSubmit = async (data: DepositRequestFormInput) => {
        const dataValidated = data as DepositRequestFormValues;

        try {
            let depositResponse = {} as any;

            if (dataValidated.depositType?.value === DepositType.FIAT) {
                const payload = {
                    entityId: foundEntity.entityId,
                    amount: parseFloat(dataValidated.amount),
                    bankInfoId: dataValidated.bankInfoId.value,
                    requestDescription: dataValidated.requestDescription || null,
                    currencyId: dataValidated.currencyId.value
                };
                depositResponse = await axios.post(portalAPI('/api/requests/deposit/fiat'), payload, {
                    headers: { Authorization: `Bearer ${jwt}` }
                });
            } else {
                const payload = {
                    entityId: foundEntity.entityId,
                    amount: parseFloat(dataValidated.amount),
                    walletId: dataValidated.walletId.value,
                    requestDescription: dataValidated.requestDescription || null,
                    currencyId: dataValidated.currencyId.value
                };
                depositResponse = await axios.post(portalAPI('/api/requests/deposit/crypto'), payload, {
                    headers: { Authorization: `Bearer ${jwt}` }
                });
            }

            if (dataValidated.supportingDetailsType.value === 'file' && files.length && depositResponse?.data?.id)
                await uploadFile(depositResponse.data.id, foundEntity.entityId);
            else if (
                dataValidated.supportingDetailsType.value === 'hash' &&
                data.transactionHash &&
                depositResponse?.data?.id
            )
                await uploadTransactionHash(depositResponse.data.id, foundEntity.entityId, data.transactionHash);

            if (dataValidated.depositType?.value === DepositType.FIAT) {
                const title = 'Fiat Deposit';
                const body = 'Deposit request submitted successfully';
                toastSuccess({ body, title });
            } else {
                const title = 'Crypto Deposit';
                const body = 'Deposit request submitted successfully';
                toastSuccess({ body, title });
            }

            handlers.close();
        } catch (err: any) {
            setError(err?.response?.data || err);
        }
    };

    const onError = (e: FieldErrors) => console.log(e);

    useEffect(() => {
        if (opened && walletOptions && walletOptions.length > 0 && walletOptions.length < 2) {
            setValue('walletId', walletOptions[0], { shouldValidate: true });
        }
    }, [walletOptions]);

    useEffect(() => {
        if (opened && bankInfoOptions && bankInfoOptions.length > 0 && bankInfoOptions.length < 2) {
            setValue('bankInfoId', bankInfoOptions[0], { shouldValidate: true });
        }
    }, [bankInfoOptions]);

    useEffect(() => {
        if (opened && selectedDepositType?.value) {
            const isFiat = selectedDepositType.value === DepositType.FIAT;
            reset({
                ...defaultValues,
                depositType: selectedDepositType,
                currencyId: isFiat ? defaultCurrencyId : defaultAssetId,
                supportingDetailsType: isFiat ? supportingDetailsTypes[0] : supportingDetailsTypes[1]
            });
        }
    }, [selectedDepositType?.value]);

    useEffect(() => {
        if (files.length) {
            setValue('supportingDetails', 'File Uploaded');
        } else {
            setValue('supportingDetails', null);
        }
        trigger('supportingDetails');
    }, [files]);

    useEffect(() => {
        if (opened && selectedCurrency?.value) {
            const isFiat = selectedDepositType?.value === DepositType.FIAT;
            reset({
                ...defaultValues,
                bankInfoId: selectedBankInfo?.value ? selectedBankInfo : defaultBankInfoId,
                walletId: selectedWalletId?.value ? selectedWalletId : defaultWalletId,
                depositType: selectedDepositType,
                currencyId: selectedCurrency,
                supportingDetailsType: isFiat ? supportingDetailsTypes[0] : supportingDetailsTypes[1]
            });
        }
    }, [selectedCurrency?.value]);

    useEffect(() => {
        if (opened && selectedCurrency?.value) {
            if (!hasAccount || !bankInfoOptions.length) {
                if (selectedDepositType?.value) setError({ message: setOfErrorMessages[selectedDepositType.value] });
            } else {
                setError({ message: '' });
            }
        }
    }, [bankInfoOptions, walletOptions, selectedCurrency, setValue]);

    useEffect(() => {
        if (!opened) {
            reset(defaultValues, { keepIsValid: false });
            setError({ message: '' });
        }
    }, [opened]);

    return (
        <Modal {...props} size="max-w-2xl">
            <div className="h-full flex flex-col lg:block lg:h-auto">
                <ModalHeader>
                    <ModalTitle>Deposits</ModalTitle>
                    <ModalClose handlers={handlers} />
                </ModalHeader>
                <Divider />
                <FormProvider {...formInstance}>
                    <form
                        autoComplete="off"
                        className="h-full flex flex-col"
                        onSubmit={handleSubmit(onSubmit, onError)}>
                        <Divider />
                        <div className="flex flex-col flex-1 basis-0 lg:flex-auto h-auto overflow-y-scroll lg:overflow-visible">
                            <div className="flex-1 basis-0">
                                <Drawer.SubBody>
                                    <InputController
                                        name="depositType"
                                        label="Deposit Type"
                                        placeholder="Deposit Type"
                                        required>
                                        <Select options={depositTypeOptions} />
                                    </InputController>
                                    <InputController
                                        name="currencyId"
                                        label={selectedDepositType?.value === 'FIAT' ? 'Currency' : 'Asset'}
                                        asyncInput={!jwt || isCurrenciesLoading}
                                        placeholder="Currency"
                                        required>
                                        <FilterSelect options={currencyOptions} enableSorting />
                                    </InputController>
                                    <InputController
                                        name="amount"
                                        label="Amount"
                                        placeholder="Amount"
                                        required
                                        disabled={!selectedCurrency?.value || !hasAccount}>
                                        <NumberInput />
                                    </InputController>
                                    <InputController
                                        name="requestDescription"
                                        label="Request Description"
                                        placeholder="Request Description"
                                        alignStart>
                                        <TextArea rows={2} />
                                    </InputController>
                                </Drawer.SubBody>
                                <Divider />
                                <Drawer.SubBody>
                                    {selectedDepositType?.value === DepositType.FIAT ? (
                                        <>
                                            <InputController
                                                name="bankInfoId"
                                                label="Bank Account"
                                                placeholder="Bank Account"
                                                asyncInput={isBankInfoLoading}
                                                disabled={bankInfoOptions && bankInfoOptions.length < 2}
                                                required>
                                                <Select options={bankInfoOptions} enableSorting />
                                            </InputController>
                                            <Entry label={'Bank Name'} value={accountDetails?.bankName} disabled />
                                            <Entry
                                                label={'Account Number / IBAN code'}
                                                value={
                                                    accountDetails?.accountNumber ||
                                                    addSpacingToCharacter(accountDetails?.ibanCode, 4)
                                                }
                                                disabled
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <InputController
                                                name="walletId"
                                                label="Wallet"
                                                placeholder="Wallet"
                                                asyncInput={isWalletLoading}
                                                disabled={walletOptions && walletOptions.length < 2}
                                                required>
                                                <Select options={walletOptions} enableSorting />
                                            </InputController>

                                            <Entry label={'Network'} value={walletDetails.network.label} disabled />
                                            <Entry label={'Wallet Address'} value={walletDetails.address} disabled />
                                        </>
                                    )}
                                    <ErrorMessage error={error} />
                                </Drawer.SubBody>
                                <Divider />
                                <Drawer.SubBody className="flex-1 basis-0">
                                    <>
                                        {selectedSupportingDetailsType?.value === 'file' && (
                                            <div className="h-full lg:min-h-60 flex-1 basis-0">
                                                <RequestFileProofUpload
                                                    setError={setError}
                                                    getFilesData={setFiles}
                                                    requestDetails={{ entityId: foundEntity?.entityId, requestId: 0 }}
                                                />
                                            </div>
                                        )}
                                        {selectedSupportingDetailsType?.value === 'hash' && (
                                            <InputController
                                                name="transactionHash"
                                                label="Transaction Hash"
                                                placeholder="Transaction Hash">
                                                <Input />
                                            </InputController>
                                        )}
                                    </>
                                </Drawer.SubBody>
                            </div>
                            <Divider />
                            <Drawer.Footer>
                                <button
                                    type="button"
                                    className="rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500"
                                    onClick={() => handlers.close()}>
                                    Cancel
                                </button>
                                <Button className="!w-auto" isLoading={isSubmitting} disabled={!isValid}>
                                    Submit Request
                                </Button>
                            </Drawer.Footer>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>
    );
};

export default DepositRequestModal;

const RedirectToPortal = () => {
    const credentials = useAppSelector(selectCredentials);
    const { onXplorPortal } = useXplorPortal(credentials);
    return (
        <span>
            Click
            <button onClick={onXplorPortal} className="text-neutral-300 hover:underline mx-1">
                here
            </button>
            to access AuricPortal
        </span>
    );
};
