import { timezoneOptions } from '@/components/modal/SettingsModal/GeneralTab';
import {
    notificationDurationOptions,
    notificationShowOptions
} from '@/components/modal/SettingsModal/NotificationsTab';
import {
    defaultLimitOrderDurationOptions,
    defaultMarketOrderDurationOptions,
    shortSellPreventionOptions,
    twoClickTradingOptions
} from '@/components/modal/SettingsModal/TradingTab';
import { Modify } from '@/model/common';
import { v4 as uuidv4 } from 'uuid';
import { AuthState } from '../reducers/authSlice';
import { LiteMarketState } from '../reducers/liteMarketSlice';
import { SettingState } from '../reducers/settingSlice';
import { TraderMarketState } from '../reducers/traderMarketSlice';
import { XplorSpotLayoutState, initialState } from '../reducers/xplorSpotLayoutSlice';
import { RootState } from '../store';

export const migrations: any = {
    0: (previousVersionState) => {
        const duration =
            notificationDurationOptions.find(
                (option) => option.value === previousVersionState?.settings?.notifications?.duration
            ) || notificationDurationOptions[1];
        const twoClickTrading =
            twoClickTradingOptions.find(
                (option) => option.value === previousVersionState?.settings?.trading?.twoClickTrading
            ) || twoClickTradingOptions[0];

        return {
            ...previousVersionState,
            settings: {
                notifications: {
                    duration
                },
                trading: {
                    twoClickTrading
                }
            }
        };
    },
    1: (previousVersionState: RootState) => {
        const marketPair = previousVersionState?.marketPair;

        return {
            ...previousVersionState,
            marketPair: {
                ...marketPair,
                pairs: marketPair.pairs.map((pair) => ({
                    celer: pair.celer,
                    netdania: pair.celer.replace('/', ''),
                    show: pair.show
                })),
                favouritePairs: marketPair.favouritePairs.map((pair) => ({
                    celer: pair.celer,
                    netdania: pair.celer.replace('/', ''),
                    show: pair.show
                })),
                activePair: {
                    celer: marketPair.activePair.celer,
                    netdania: marketPair.activePair.celer?.replace('/', ''),
                    show: marketPair.activePair.show
                }
            }
        };
    },
    2: (previousVersionState: RootState) => {
        const marketPair = previousVersionState?.marketPair;

        return {
            ...previousVersionState,
            marketPair: {
                ...marketPair,
                pairs: [],
                favouritePairs: [],
                activePair: {
                    celer: null,
                    netdania: null,
                    show: null
                }
            }
        };
    },
    3: (previousVersionState: RootState) => {
        const settings = previousVersionState?.settings;
        const marketPair = previousVersionState?.marketPair;

        return {
            ...previousVersionState,
            settings: {
                ...settings,
                notifications: {
                    show: notificationShowOptions[0],
                    duration: notificationDurationOptions[1]
                }
            } as SettingState,
            marketPair: {
                ...marketPair,
                activePair: {
                    celer: 'BTC/USD',
                    netdania: 'BTC/USD',
                    show: 'BTC/USD'
                }
            }
        };
    },
    4: (previousVersionState: RootState) => {
        const settings = previousVersionState?.settings;

        return {
            ...previousVersionState,
            settings: {
                ...settings,
                trading: {
                    twoClickTrading: twoClickTradingOptions[0]
                }
            } as SettingState
        };
    },
    5: (previousVersionState: RootState) => {
        const settings = previousVersionState?.settings;

        return {
            ...previousVersionState,
            settings: {
                ...settings,
                notifications: {
                    ...settings.notifications,
                    show: notificationShowOptions[0]
                }
            } as SettingState
        };
    },
    6: (previousVersionState: RootState) => {
        const liteMarket = previousVersionState?.liteMarket;

        return {
            ...previousVersionState,
            liteMarket: {
                ...liteMarket,
                liteMarkets: liteMarket.liteMarkets.map((market) => ({
                    ...market,
                    toName: 'USD',
                    toLogo: 'usd'
                }))
            } as LiteMarketState
        };
    },
    7: (previousVersionState: Modify<RootState, { layout: XplorSpotLayoutState }>) => {
        const settings = previousVersionState?.settings;
        const layout = previousVersionState?.layout;

        return {
            ...previousVersionState,
            settings: {
                ...settings,
                trading: {
                    ...settings.trading,
                    shortSellPrevention: shortSellPreventionOptions[0]
                }
            } as SettingState,
            xplorSpotLayout: layout || initialState
        };
    },
    8: (previousVersionState: RootState) => {
        const auth = previousVersionState?.auth;
        const accounts = auth.user?.accounts as any as string[];

        const modifiedAccounts =
            accounts?.map((account: any) => {
                if (account.code) {
                    return account;
                } else {
                    return { code: account, properties: [] };
                }
            }) || [];

        const modifiedUser = auth.user
            ? {
                  ...auth.user,
                  accounts: modifiedAccounts
              }
            : null;

        return {
            ...previousVersionState,
            auth: {
                ...auth,
                user: modifiedUser
            } as AuthState
        };
    },
    9: (previousVersionState: RootState) => {
        return {
            ...previousVersionState,
            xplorTraderLayout: {
                mobileNode: undefined,
                tabletNode: undefined,
                laptopNode: undefined,
                desktopNode: undefined
            } as XplorSpotLayoutState
        };
    },
    10: (previousVersionState: RootState) => {
        return {
            ...previousVersionState,
            traderMarket: {
                status: 'idle',
                watchlists: [
                    {
                        id: uuidv4(),
                        name: 'Watchlist',
                        markets: []
                    }
                ]
            } as TraderMarketState
        };
    },
    11: (previousVersionState: RootState) => {
        return {
            ...previousVersionState,
            traderMarket: {
                status: 'idle',
                watchlists: [
                    {
                        id: uuidv4(),
                        name: 'Watchlist',
                        markets: [
                            { celer: 'BTC/USD', show: 'BTC/USD', netdania: 'BTC/USD' },
                            { celer: 'ETH/USD', show: 'ETH/USD', netdania: 'ETH/USD' },
                            { celer: 'XRP/USD', show: 'XRP/USD', netdania: 'XRP/USD' },
                            { celer: 'EUR/USD', show: 'EUR/USD', netdania: 'EUR/USD' },
                            { celer: 'XAU/USD', show: 'XAU/USD', netdania: 'XAU/USD' },
                            { celer: 'USD/JPY', show: 'USD/JPY', netdania: 'USD/JPY' },
                            { celer: 'GEM/EUR', show: 'GEM/EUR', netdania: 'GEM/EUR' },
                            { celer: 'DJI/USD', show: 'DJI/USD', netdania: 'DJI/USD' },
                            { celer: 'UKI/GBP', show: 'UKI/GBP', netdania: 'UKI/GBP' }
                        ]
                    }
                ],
                selectedWatchList: undefined
            } as TraderMarketState
        };
    },
    12: (previousVersionState: RootState) => {
        const settings = previousVersionState?.settings;

        return {
            ...previousVersionState,
            settings: {
                ...settings,
                trading: {
                    ...settings.trading,
                    defaultOrderDuration: defaultMarketOrderDurationOptions[0]
                }
            } as SettingState
        };
    },
    13: (previousVersionState: RootState) => {
        const auth = previousVersionState?.auth || {};

        return {
            ...previousVersionState,
            auth: {
                ...auth,
                user: auth.user
                    ? {
                          ...auth.user,
                          accounts: auth.user?.accounts.map((account) => ({
                              ...account,
                              name: account.code || ''
                          }))
                      }
                    : undefined,
                currentAccountName: auth.currentAccount
            } as AuthState
        };
    },
    14: (previousVersionState: RootState) => {
        const settings = previousVersionState?.settings || {};

        return {
            ...previousVersionState,
            settings: {
                ...settings,
                trading: {
                    ...settings.trading,
                    defaultMarketOrderDuration:
                        settings.trading.defaultOrderDuration || defaultMarketOrderDurationOptions[0],
                    defaultLimitOrderDuration: defaultLimitOrderDurationOptions[0]
                }
            } as SettingState
        };
    },
    15: (previousVersionState: RootState) => {
        const settings = previousVersionState?.settings || {};

        return {
            ...previousVersionState,
            settings: {
                ...settings,
                general: {
                    timezone: timezoneOptions[0]
                }
            } as SettingState
        };
    },
    16: (previousVersionState: RootState) => {
        const traderMarket = previousVersionState?.traderMarket || {};

        return {
            ...previousVersionState,
            traderMarket: {
                ...traderMarket,
                watchlists: [
                    {
                        id: uuidv4(),
                        name: 'Watchlist',
                        markets: [
                            { celer: 'AUD/USD', show: 'AUD/USD', netdania: 'AUD/USD' },
                            { celer: 'GBP/USD', show: 'GBP/USD', netdania: 'GBP/USD' },
                            { celer: 'EUR/JPY', show: 'EUR/JPY', netdania: 'EUR/JPY' },
                            { celer: 'EUR/USD', show: 'EUR/USD', netdania: 'EUR/USD' },
                            { celer: 'XAU/USD', show: 'XAU/USD', netdania: 'XAU/USD' },
                            { celer: 'USD/JPY', show: 'USD/JPY', netdania: 'USD/JPY' },
                            { celer: 'GEM/EUR', show: 'GEM/EUR', netdania: 'GEM/EUR' },
                            { celer: 'DJI/USD', show: 'DJI/USD', netdania: 'DJI/USD' },
                            { celer: 'UKI/GBP', show: 'UKI/GBP', netdania: 'UKI/GBP' }
                        ]
                    }
                ]
            } as TraderMarketState
        };
    },
    17: (previousVersionState: RootState) => {
        return {
            ...previousVersionState,
            traderMarket: {
                status: 'idle',
                watchlists: [
                    {
                        id: uuidv4(),
                        name: 'Watchlist',
                        markets: [
                            { celer: 'AUD/USD', show: 'AUD/USD', netdania: 'AUD/USD' },
                            { celer: 'GBP/USD', show: 'GBP/USD', netdania: 'GBP/USD' },
                            { celer: 'EUR/JPY', show: 'EUR/JPY', netdania: 'EUR/JPY' },
                            { celer: 'EUR/USD', show: 'EUR/USD', netdania: 'EUR/USD' },
                            { celer: 'XAU/USD', show: 'XAU/USD', netdania: 'XAU/USD' },
                            { celer: 'USD/JPY', show: 'USD/JPY', netdania: 'USD/JPY' },
                            { celer: 'GEM/EUR', show: 'GEM/EUR', netdania: 'GEM/EUR' },
                            { celer: 'DJI/USD', show: 'DJI/USD', netdania: 'DJI/USD' },
                            { celer: 'UKI/GBP', show: 'UKI/GBP', netdania: 'UKI/GBP' }
                        ]
                    }
                ],
                selectedWatchList: undefined
            } as TraderMarketState
        };
    }
};
