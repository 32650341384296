import { useEffect, useState } from 'react';
import Loader from '../common/Loader';
import Modal from './Modal';

interface RefreshVersionModalProps {
    opened: boolean;
    handlers: {
        open: () => void;
        close: () => void;
        toggle: () => void;
    };
}

const expirationDuration = +(window.config?.refreshVersionInterval || 1500);

const RefreshVersionModal = (props: RefreshVersionModalProps) => {
    const { opened } = props;

    const [expirationTimer, setExpirationTimer] = useState<number>(expirationDuration);

    useEffect(() => {
        if (opened) setExpirationTimer(expirationDuration);
    }, [opened]);

    useEffect(() => {
        if (opened) {
            const timer = setTimeout(() => setExpirationTimer(expirationTimer - 1000), 1000);
            if (expirationTimer <= 0) {
                clearTimeout(timer);
                location.reload();
            }
        }
    }, [opened, expirationTimer]);

    return (
        <Modal {...props} className="h-auto" overlayOpacity="bg-opacity-100">
            <div className="flex items-center gap-3 text-neutral-200 p-2 sm:p-4">
                <Loader className="w-5 h-5 mr-0 ml-0 text-brand-primary-light" />
                <div>Updating to the latest Auric Trader...</div>
            </div>
        </Modal>
    );
};

export default RefreshVersionModal;
